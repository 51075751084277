import axios from 'axios';
//
// STATE
//
const state = () => ({
    featureFlags: [],
    featureFlagsMap: {},
});
//
// GETTERS
//
const getters = {
    getFeatureFlagByName(state) {
        return (name) => state.featureFlagsMap[name];
    },
};
//
// MUTATIONS
//
const mutations = {
    setFeatureFlags(state, value) {
        value.forEach((flag) => {
            state.featureFlagsMap[flag.featureFlagName] = flag.isEnabled;
        });
    },
};
//
// ACTIONS
//
const actions = {
    async fetchFeatureFlags({commit, rootGetters}) {
        const response = await this._vm.$http.get(
            `/featureflag/${rootGetters['user/activeCustomerBusinessUnit'].replace(
                ' BU',
                ''
            )}/get-all-feature-flags/`
        );

        if (response.status == 200) {
            commit('setFeatureFlags', response.data);
        } else {
            console.log('error fetching Feature Flags');
            console.log(response.status);
        }
    },
};

//
// DEFAULT EXPORT
//
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
